var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"attendee-record",on:{"mouseover":function($event){return _vm.mouseOver()},"mouseout":function($event){return _vm.mouseOut()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-9"},[_c('h2',{staticClass:"course-name"},[_vm._v(" "+_vm._s(_vm.attendee.course.name)+" "),(
            _vm.attendee.course.name === 'CKS Simulator' &&
              (_vm.attendee.courseVersion === '1.30b' ||
                _vm.attendee.courseVersion === '1.31')
          )?_c('small',[_vm._v("BETA")]):_vm._e()]),(
          !_vm.attendee.course.type.includes('lfct') &&
            !_vm.attendee.course.type.includes('lfcs')
        )?_c('small',[_vm._v("Kubernetes "+_vm._s(_vm.removeLetters(_vm.attendee.courseVersion)))]):_vm._e(),(_vm.attendee.courseVersion === '1.30b')?_c('small',{staticStyle:{"color":"white"}},[_vm._v("b")]):_vm._e()]),_c('div',{staticClass:"col-3"},[_c('p',{staticClass:"pull-right light-text"},[_vm._v(_vm._s(_vm.attendee.uuidShort))])])]),_c('br'),_c('div',{staticClass:"pull-left"},[_c('small',[_vm._v(_vm._s(_vm.infoText()))])]),(!_vm.attendee.activated)?_c('div',{staticClass:"pull-right light-text"},[_vm._v(" "+_vm._s(_vm.activateBeforeText())+" ")]):_vm._e(),_c('div',{staticClass:"clearfix"})])}
var staticRenderFns = []

export { render, staticRenderFns }
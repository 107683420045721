<template>
  <div>
    <DraggableDiv>
      <template slot="header">
        Instructions
        <span class="pull-right close" v-on:click="close()">
          <font-awesome-icon icon="times-circle" size="1x" />
        </span>
      </template>
      <template slot="main">
        <div class="instructions">
          <div v-if="attendee.course.type.includes('lfcs')">
            <div v-if="!supportedBrowser()" class="alert alert-danger">
              You're using an unsupported browser. Only latest Chrome and
              Firefox are supported!
            </div>
            <div v-if="linuxAndChromeUsed()" class="alert alert-danger">
              There can be keyboard issues when using Linux+Chrome. Switch to
              Chromium or Firefox.
            </div>
            <p>
              You can access these instructions any time via "Exam Information".
            </p>

            <br />

            <div
              style="background-color: rgba(28,115,150,0.2); padding: 10px; margin: 0 20px"
            >
              <h5><strong>Remote Desktop</strong></h5>
              <p>
                Since 2022 the Exam UI is using a Remote Desktop (XFCE) instead
                of a Remote Terminal and the Linux Foundation released an
                <a
                  href="https://training.linuxfoundation.org/blog/update-on-certification-exam-proctoring-migration/?utm_source=lftraining&utm_medium=twitter&utm_campaign=blog"
                  target="_blank"
                  >update</a
                >
                about this. Coming from OSX/Windows there will be changes in
                copy&paste for example. This simulator provides a very similar
                Remote Desktop environment.
              </p>

              <p>
                There are still differences between the real exams and the
                simulators, like:
              </p>

              <ul>
                <li>
                  You'll need to use the PSI Secure Browser to access the real
                  exams whereas you can use Chrome/Firefox to access the
                  simulators
                </li>
                <li>
                  In the simulators you can use the top navigation to switch
                  back to the old Remote Terminal in case of issues, this isn't
                  possible in the real exams
                </li>
              </ul>
            </div>

            <br />

            <h5>Countdown / Timer</h5>
            <p>
              The 120 minutes countdown will start once the session is started.
              It will be shown on top left. The countdown is just an just an
              indicator for yourself and won't revoke access to your environment
              when finished.
            </p>

            <h5>Servers</h5>
            <ul>
              <li>
                You have access to 5 servers:
                <ul>
                  <li><code>terminal</code> <i>(default)</i></li>
                  <li><code>web-srv1</code></li>
                  <li><code>app-srv1</code></li>
                  <li><code>data-001</code></li>
                  <li><code>data-002</code></li>
                </ul>
              </li>
              <li>
                The server on which to solve a question is mentioned in the
                question text. If <strong>no server is mentioned</strong> you'll
                need to create your solution on the default
                <code>terminal</code>
              </li>
              <li>
                If you're asked to create solution files at
                <code>/opt/course/*</code>, then
                <strong>always do this</strong> on your main
                <code>terminal</code>
              </li>
              <li>
                You can connect to each server using ssh, like
                <code>ssh web-srv1</code>
              </li>
              <li>
                All server addresses are configured in
                <code>/etc/hosts</code> on each server
              </li>
              <li>
                Nested ssh is <strong>not possible</strong>: you can only
                connect to each server from your main <code>terminal</code>
              </li>
              <li>
                It's not possible to restart single servers. If deeper issues or
                misconfigurations occurred then the only solution might be to
                restart the complete simulator. This is possible using top menu
                by selecting "Restart Session"
              </li>
            </ul>

            <h5>Solutions & Score</h5>
            <p>
              When the countdown reaches 0 you'll be able to see the proposed
              solutions for each task and your score. You can also access these
              earlier by selecting "Exam Controls -> Answers & Score". But it's
              recommended to try solving by yourself at first.
            </p>

            <h5>36hrs Access</h5>
            <p>
              You'll have access to your simulator environment during the next
              36hrs. You'll always have access to the questions and solutions.
            </p>

            <h5>Browser reload or close</h5>
            <p>
              For the 36hrs your session will be kept running in the background.
              You can close the window or even use a different browser without
              losing changes.
            </p>

            <h5>Rules</h5>
            <p>
              You're not allowed to use any external resources. Feel free to use
              the man pages for help.<br />
            </p>

            <h5>Difficulty</h5>
            <p>
              This simulator is more difficult than the real certification. We
              think this gives you a greater learning effect and also confidence
              to score in the real exam. Most of the simulator scenarios require
              good amount of work and can be considered "hard". In the real exam
              you will also face these "hard" scenarios, just less often. There
              are probably also fewer questions in the real exam.
            </p>

            <h5>Content</h5>
            <p>
              All LFCS simulator sessions will have identical content/scenarios.
            </p>

            <h5>Slow or interrupted connection?</h5>
            <p>
              If you experience any kind of issues please make sure all points
              here are complied with:
            </p>
            <ul>
              <li>
                <strong>Browser</strong>: only latest Chrome and latest Firefox
                are supported
              </li>
              <li>
                <strong>Ubuntu+Chrome</strong>: users report keyboard issues,
                switch to Firefox or Chromium
              </li>
              <li>
                <strong>Extensions</strong>: disable ALL extensions/plugins and
                run in private mode
              </li>
              <li><strong>VPN/Proxy</strong>: don't use a VPN/Proxy</li>
              <li>
                <strong>Internet</strong>: use a stable internet connection,
                with low usage by others
              </li>
            </ul>

            <h5>Help / Support</h5>
            <p>
              <router-link :to="{ name: 'FaqSessionRuntime' }" target="_blank"
                >FAQ</router-link
              >
              for answers
              <br />
              <a href="https://killer.sh/slack" target="_blank">Slack</a>
              for scenario discussions
              <br />
              <router-link :to="{ name: 'Support' }" target="_blank"
                >Support</router-link
              >
              for session/account issues
            </p>
            <div style="margin-bottom: 50px"></div>
          </div>
          <div v-else-if="attendee.course.type.includes('lfct')">
            <div v-if="!supportedBrowser()" class="alert alert-danger">
              You're using an unsupported browser. Only latest Chrome and
              Firefox are supported!
            </div>
            <div v-if="linuxAndChromeUsed()" class="alert alert-danger">
              There can be keyboard issues when using Linux+Chrome. Switch to
              Chromium or Firefox.
            </div>
            <p>
              You can access these instructions any time via "Exam Information".
            </p>

            <br />

            <h5>Countdown / Timer</h5>
            <p>
              The 120 minutes countdown will start once the session is started.
              It will be shown on top left. The countdown is just an just an
              indicator for yourself and won't revoke access to your environment
              when finished.
            </p>

            <h5>Virtual Machines</h5>
            <ul>
              <li>
                Each question is completed in a dedicated Linux Virtual Machine
                (VM). These VM's are created fresh for every question and are
                not stored
              </li>
              <li>
                In case of any issues or if you like to start a question again
                you can click on "Reset" to recreate a VM
              </li>
              <li>
                When you complete a question you have to click on "Submit" to
                submit your results for scoring
              </li>
              <li>
                Before you can navigate to a different question you have to
                click on either "Submit" or "Restart", where "Restart" will
                discard your started solution
              </li>
              <li>
                It's not possible to access an already submitted VM environment
                again. You can replace an already submitted solution by clicking
                on "Reattempt Task" and then clicking on "Submit" again
              </li>
            </ul>

            <h5>Solutions & Score</h5>
            <p>
              When the countdown reaches 0 you'll be able to see the proposed
              solutions for each task and your score. You can also access these
              earlier by selecting "Exam Controls -> Answers & Score". But it's
              recommended to try solving by yourself at first.
            </p>

            <h5>36hrs Access</h5>
            <p>
              You'll have access to your simulator environment during the next
              36hrs. You'll always have access to the questions and solutions.
            </p>

            <h5>Browser reload or close</h5>
            <p>
              For the 36hrs your session will be kept running in the background.
              You can close the window or even use a different browser without
              losing changes.
            </p>

            <h5>Rules</h5>
            <p>
              You're not allowed to use any external resources. Feel free to use
              the man pages for help.<br />
            </p>

            <h5>Difficulty</h5>
            <p>
              This simulator is more difficult than the real certification. We
              think this gives you a greater learning effect and also confidence
              to score in the real exam. Most of the simulator scenarios require
              good amount of work and can be considered "hard". In the real exam
              you will also face these "hard" scenarios, just less often. There
              are probably also fewer questions in the real exam.
            </p>

            <h5>Content</h5>
            <p>
              All LFCT simulator sessions will have identical content/scenarios.
            </p>

            <h5>Slow or interrupted connection?</h5>
            <p>
              If you experience any kind of issues please make sure all points
              here are complied with:
            </p>
            <ul>
              <li>
                <strong>Browser</strong>: only latest Chrome and latest Firefox
                are supported
              </li>
              <li>
                <strong>Ubuntu+Chrome</strong>: users report keyboard issues,
                switch to Firefox or Chromium
              </li>
              <li>
                <strong>Extensions</strong>: disable ALL extensions/plugins and
                run in private mode
              </li>
              <li><strong>VPN/Proxy</strong>: don't use a VPN/Proxy</li>
              <li>
                <strong>Internet</strong>: use a stable internet connection,
                with low usage by others
              </li>
            </ul>

            <h5>Help / Support</h5>
            <p>
              <router-link :to="{ name: 'FaqSessionRuntime' }" target="_blank"
                >FAQ</router-link
              >
              for answers
              <br />
              <a href="https://killer.sh/slack" target="_blank">Slack</a>
              for scenario discussions
              <br />
              <router-link :to="{ name: 'Support' }" target="_blank"
                >Support</router-link
              >
              for session/account issues
            </p>
            <div style="margin-bottom: 50px"></div>
          </div>
          <div v-else>
            <div v-if="!supportedBrowser()" class="alert alert-danger">
              You're using an unsupported browser. Only latest Chrome and
              Firefox are supported!
            </div>
            <div v-if="linuxAndChromeUsed()" class="alert alert-danger">
              There can be keyboard issues when using Linux+Chrome. Switch to
              Chromium or Firefox.
            </div>
            <p>
              You can access these instructions any time via "Exam Information".
            </p>

            <br />

            <div
              style="background-color: rgba(28,115,150,0.2); padding: 10px; margin: 0 20px"
            >
              <h5><strong>Remote Desktop</strong></h5>
              <p>
                Since 2022 the Exam UI is using a Remote Desktop (XFCE) instead
                of a Remote Terminal and the Linux Foundation released an
                <a
                  href="https://training.linuxfoundation.org/blog/update-on-certification-exam-proctoring-migration/?utm_source=lftraining&utm_medium=twitter&utm_campaign=blog"
                  target="_blank"
                  >update</a
                >
                about this. Coming from OSX/Windows there will be changes in
                copy&paste for example. This simulator provides a very similar
                Remote Desktop environment.
              </p>

              <p>
                There are still differences between the real exams and the
                simulators, like:
              </p>

              <ul>
                <li>
                  You'll need to use the PSI Secure Browser to access the real
                  exams whereas you can use Chrome/Firefox to access the
                  simulators
                </li>
                <li>
                  You can only access certain allowed URLs in the Remote
                  Desktop's Firefox of the real exams whereas there are no such
                  restrictions in the simulators
                </li>
                <li>
                  In the simulators you can use the top navigation to switch
                  back to the old Remote Terminal in case of issues, this isn't
                  possible in the real exams
                </li>
                <li
                  v-if="
                    (attendee.courseType.includes('ckad') &&
                      attendee.courseVersion !== '1.30') ||
                      (attendee.courseType.includes('cks') &&
                        attendee.courseVersion !== '1.30')
                  "
                >
                  In the real exam each question will be solved on a different
                  instance whereas in the simulator multiple questions will be
                  solved on the same instances
                </li>
              </ul>
            </div>

            <br />

            <h5>Countdown / Timer</h5>
            <p>
              The 120 minutes countdown will start once the session is started.
              It will be shown on top left. The countdown is just an just an
              indicator for yourself and won't revoke access to your environment
              when finished.
            </p>

            <h5>Solutions & Score</h5>
            <p>
              When the countdown reaches 0 you'll be able to see the proposed
              solutions for each task and your score. You can also access these
              earlier by selecting "Exam Controls -> Answers & Score". But it's
              recommended to try solving by yourself at first.
            </p>

            <h5>36hrs Access</h5>
            <p>
              You'll have access to your simulator environment during the next
              36hrs. You'll always have access to the questions and solutions.
            </p>

            <h5>Browser reload or close</h5>
            <p>
              For the 36hrs your session will be kept running in the background.
              You can close the window or even use a different browser without
              losing changes.
            </p>

            <h5>Permissions</h5>
            <p>
              You have root permissions using sudo should you encounter any
              permission issues.
            </p>

            <h5>Keyboard issues <small>(some keys won't work)</small></h5>
            <p>
              For OSX it could be your keyboard layout. Please go to
              Preferences->Keyboard->Input Sources. There search for English and
              then select "ABC" (or maybe called "Default"). Switching the
              layout should solve the input issue.<br />
              For Windows try to change your keyboard layout to a different
              English one.<br />
              For Ubuntu+Chrome for example users report keyboard issues, switch
              to Firefox or Chromium. Also open killer.sh in private browser
              mode.
            </p>

            <span>
              <h5>Rules</h5>
              <p>
                You're only allowed to have one other browser tab open with:<br />
              </p>
              <ul v-if="attendee.course.type === 'ckad_simulator'">
                <li>
                  <a href="https://kubernetes.io/docs" target="_blank"
                    >https://kubernetes.io/docs</a
                  >
                </li>
                <li>
                  <a href="https://kubernetes.io/blog" target="_blank">
                    https://kubernetes.io/blog</a
                  >
                </li>
                <li>
                  <a href="https://helm.sh/docs" target="_blank">
                    https://helm.sh/docs</a
                  >
                </li>
              </ul>
              <ul v-if="attendee.course.type === 'cka_simulator'">
                <li>
                  <a href="https://kubernetes.io/docs" target="_blank"
                    >https://kubernetes.io/docs</a
                  >
                </li>
                <li>
                  <a href="https://kubernetes.io/blog" target="_blank">
                    https://kubernetes.io/blog</a
                  >
                </li>
              </ul>
              <ul v-if="attendee.course.type === 'cks_simulator'">
                <li>
                  <a href="https://kubernetes.io/docs" target="_blank"
                    >https://kubernetes.io/docs</a
                  >
                </li>
                <li>
                  <a href="https://github.com/kubernetes" target="_blank"
                    >https://github.com/kubernetes</a
                  >
                </li>
                <li>
                  <a href="https://kubernetes.io/docs" target="_blank">
                    https://kubernetes.io/blog</a
                  >
                </li>
                <li>
                  <a
                    href="https://github.com/aquasecurity/trivy"
                    target="_blank"
                  >
                    https://github.com/aquasecurity/trivy</a
                  >
                </li>
                <li>
                  <a href="https://falco.org/docs" target="_blank">
                    https://falco.org/docs</a
                  >
                </li>
                <li>
                  <a
                    href="https://gitlab.com/apparmor/apparmor/-/wikis/Documentation"
                    target="_blank"
                  >
                    https://gitlab.com/apparmor/apparmor/-/wikis/Documentation</a
                  >
                </li>
              </ul>
            </span>

            <h5>Notepad</h5>
            <p>
              Use Application->Accessories->Mousepad to write down notes for
              yourself during the exam.
            </p>
            <h5>Difficulty</h5>
            <p v-if="attendee.courseType.includes('cks')">
              The difficulty is comparable to the real exam, although there are
              probably fewer questions in the real exam.
            </p>
            <p v-else>
              The difficulty is higher compared to the real exam. Most of the
              simulator questions require good amount of work and can be
              considered hard. In the real exam you will also face these hard
              scenarios, just less often. There are probably also fewer
              questions in the real exam.
            </p>
            <h5>Make sure</h5>
            <p>
              Only edit/modify/delete those resources you want to act on. In
              most scenarios there are existing resources which could be part of
              other questions. Just like in a real world cluster.
            </p>
            <h5>Content</h5>
            <p>
              All simulator sessions of the same type (CKS|CKA|CKAD|LFCS|LFCT)
              will have identical content/scenarios.
            </p>

            <h5>Slow or interrupted connection?</h5>
            <p>
              If you experience any kind of issues please make sure all points
              here are complied with:
            </p>
            <ul>
              <li>
                <strong>Browser</strong>: only latest Chrome and latest Firefox
                are supported
              </li>
              <li>
                <strong>Ubuntu+Chrome</strong>: users report keyboard issues,
                switch to Firefox or Chromium
              </li>
              <li>
                <strong>Extensions</strong>: disable ALL extensions/plugins and
                run in private mode
              </li>
              <li><strong>VPN/Proxy</strong>: don't use a VPN/Proxy</li>
              <li>
                <strong>Internet</strong>: use a stable internet connection,
                with low usage by others
              </li>
            </ul>

            <h5>Help / Support</h5>
            <p>
              <router-link :to="{ name: 'FaqSessionRuntime' }" target="_blank"
                >FAQ</router-link
              >
              for answers
              <br />
              <a href="https://killer.sh/slack" target="_blank">Slack</a>
              for scenario discussions
              <br />
              <router-link :to="{ name: 'Support' }" target="_blank"
                >Support</router-link
              >
              for session/account issues
            </p>
            <div style="margin-bottom: 50px"></div>
          </div>
        </div>
      </template>
    </DraggableDiv>
  </div>
</template>

<script>
import DraggableDiv from '@/components/util/Draggable';

export default {
  name: 'Instructions',
  components: {
    DraggableDiv,
  },
  props: {
    attendee: {
      required: true,
    },
  },
  methods: {
    close: function() {
      this.$store.dispatch('attendee/run/instructionShowToggle');
    },
    supportedBrowser: function() {
      if (navigator.userAgent.indexOf('Chrome') !== -1) {
        return true;
      } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
        return true;
      }
      return false;
    },
    linuxAndChromeUsed: function() {
      return (
        navigator.userAgent.indexOf('Chrome') !== -1 &&
        navigator.userAgent.indexOf('Linux') !== -1
      );
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';

::v-deep .draggable-container {
  left: 70px;
  top: 250px;
  height: 500px;
  width: 65vw;
  min-height: 30vh;
  min-width: 30vh;
  max-height: 500vh;
  max-width: 500vh;
}

::v-deep .draggable-container .draggable-header {
  border-top: 1px solid #1c7396;
  border-left: 1px solid #1c7396;
  border-right: 1px solid #1c7396;
}

::v-deep .draggable-container .draggable-header .close {
  cursor: pointer;
  margin-right: -10px;
}

::v-deep .draggable-container .draggable-main .instructions {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: auto;
  padding: 5px;
  border: 1px solid lightgray;
}
</style>
